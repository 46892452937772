<template>
  <div class="achievements">
    <Loader v-if="isLoading" />

    <div class="container" v-if="!isLoading">
      <div class="h4">Награды</div>
      <div class="achievement-categories-list">
        <div class="achievement-category" v-for="(category, i) in categoriesList" :key="i">
          <div class="achievement-category__title">{{ category.name }}</div>
          <div class="achievement-category__achievements">
            <div class="achievements-item" v-for="(achievement, j) in category.achievements" :key="j">
              <button @click="openModal(i, j)" class="achievements-item__preview">
                <div class="achievements-item__preview-background">
                  <img
                    class="achievements-item__preview-background-image"
                    v-lazyload="getPreviewUrl(achievement.file, false)"
                  />
                </div>
                <img class="achievements-item__preview-image" v-lazyload="getPreviewUrl(achievement.file, true)" />
                <div class="achievements-item__overlay">
                  <img src="@/assets/img/double-arrow.svg" />
                </div>
              </button>
              <div class="achievements-item__text">
                <p class="achievements-item__text-name">{{ achievement.name }}</p>
                <p class="achievements-item__text-description">
                  {{ achievement.description || achievement.date | moment("DD MMMM YYYY") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <MediaModal
        align="center"
        :video-modal="false"
        :is-first-index="isFirstIndex"
        :is-last-index="isLastIndex"
        :current-image="currentImage"
        @slideNext="slideNext"
        @slidePrev="slidePrev"
        @close="closeModal"
        v-if="isModalOpen"
      />
    </transition>
  </div>
</template>

<script>
import '@/assets/sass/achievements.sass';
import { getPreviewUrl } from '@/helpers';
import { mapState } from 'vuex';
import config from '@/config';
import MediaModal from '@/components/Archive/MediaModal.vue';
import $ from 'jquery';
import Loader from '@/components/Loader.vue';

export default {
  name: 'Achievements',
  components: { Loader, MediaModal },
  data() {
    return {
      isLoading: true,
      isModalOpen: false,
      currentImageIndex: 0,
      currentCategoryIndex: 0,
    };
  },
  computed: {
    ...mapState({
      categoriesList: (state) => state.achievements.achievementCategoriesList,
    }),
    API_URL() {
      return config.API_URL;
    },
    currentImage() {
      if (!this.categoriesList.length) return { file: {} };

      const { achievements } = this.categoriesList[this.currentCategoryIndex];
      if (!achievements.length) return { file: {} };

      return achievements[this.currentImageIndex];
    },
    isFirstIndex() {
      return this.currentImageIndex === 0;
    },
    isLastIndex() {
      if (!this.categoriesList.length) return false;
      return this.currentImageIndex === this.categoriesList[this.currentCategoryIndex].achievements.length - 1;
    },
  },
  methods: {
    loadData() {
      this.$store.dispatch('getAchievementCategoriesList').then(() => {
        this.isLoading = false;
      });
    },
    slidePrev() {
      if (this.isFirstIndex) return;
      this.currentImageIndex -= 1;
    },
    slideNext() {
      if (this.isLastIndex) return;
      this.currentImageIndex += 1;
    },
    openModal(i, j) {
      this.currentCategoryIndex = i;
      this.currentImageIndex = j;
      this.isModalOpen = true;
      $('body').css('overflow', 'hidden');
    },
    closeModal() {
      this.isModalOpen = false;
      $('body').css('overflow', 'auto');
    },
    getPreviewUrl(data) {
      return getPreviewUrl(data);
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadData();
    this.$emit('setLastCrumb', 'Награды');
  },
};
</script>
